import styled from "styled-components";

const Card = styled.div`
    background-color: #333A40;
    color:#f6f6f6;
    margin-top: 20px;
    border-radius: 20px;
    margin-inline: auto;`
;

export default Card;